import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const EditPo = (prop) => {
    prop.setShow(true);
    const [key, setKey] = useState('personal');
   const {id}=useParams()
    const userToken = localStorage.getItem("userToken");
    const navigate = useNavigate();
    const [newProduct, setNewProduct] = useState({ name: '',uom:'', qty: 0, price: 0,hsn:'' });
    const [formData, setFormData] = useState({});


    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}purchaseorder/${id}`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {
                setFormData({...response?.data?.purchaseOrder,cgst:9,igst:18,sgst:9});
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])





    const handleAddProduct = () => {
        const { name,uom, qty, price,hsn } = newProduct;
        const updatedProducts = [...formData.productDesc, { name,uom, qty, price,hsn }];
        setFormData({
            ...formData,
            productDesc: updatedProducts,
        });
        setNewProduct({ name: '',uom:'', qty: 0, price: 0 });
    };

    const handleDeleteProduct = (index) => {
        const updatedProducts = formData.productDesc.filter((product, i) => i !== index);
        setFormData({ productDesc: updatedProducts });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setNewProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
    };


    const handleSubmit = async (e) => {

        e.preventDefault();
        let data = JSON.stringify(formData);


        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}purchaseorder/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            data: data
        }
        axios.request(config)
            .then((response) => {
                if (response?.data.status) {
                    Swal.fire({
                        icon: "success",
                        title: "Purchase Order Edited Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                  navigate("/purchaseorders")
                }

            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "warning",
                    title: error.response?.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });

    };

    return (
        <div className="container-right">
            <div className='container mt-5 mb-5'>
                <h2 className='font-weight-bolder mt-4 mb-4'>  New Purchase Order</h2>
                <Form onSubmit={handleSubmit}>
                    <div className='create-invoice'>
                        <div className='label-input'>
                            <label>Organisation Name*</label>
                            <Form.Control
                                className='border mb-3'
                                type='text'
                                placeholder="Vendor Name"
                                name="vendorIds"
                                value={formData.vendorName}
                                disabled
                               
                              />
                        </div>
                    </div>

                    <div className='customer-invoice-tabs'>

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="personal" title="Item Table">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="productDesc">

                                        <div className='mb-4'>
                                            <div className="row">
                                                <div className="col-sm product">
                                                    <label>
                                                        Product Name:
                                                        <textarea rows={1} type="text" className="form-control" name="name" value={newProduct.name} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        UOM:
                                                        <select className="form-control" name="uom" value={newProduct.uom} onChange={handleInputChange2}>
                                                            <option></option>
                                                            <option value={'Nos'}>Nos</option>
                                                            <option value={'Litre'}>Litre</option>
                                                            <option value={'Kgs'}>Kgs</option>
                                                            <option value={'Lots'}>Lots</option>
                                                            
                                                            </select>
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        HSN Code:
                                                        <input type="text" className="form-control" name="hsn" value={newProduct.hsn} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        qty:
                                                        <input type="number" className="form-control" name="qty" value={newProduct.qty} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        Price:
                                                        <input type="number" className="form-control" name="price" value={newProduct.price} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm-auto align-self-end">
                                                    <button type='button' className="btn btn-primary" id="add" onClick={handleAddProduct}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='added-items'>
                                            <h6>Added Items</h6>
                                            <div className='mb-4'>
                                                <div className="row">
                                                    <div className="col-sm added">
                                                        {formData?.productDesc?.map((product, index) => (
                                                            <>
                                                                <label>
                                                                    Product Name:
                                                                    <textarea rows={1} type="text" className="form-control" value={product.name} disabled />
                                                                </label>
                                                                <label>
                                                                    UOM:
                                                                    <input type="text" className="form-control" value={product.uom} disabled />
                                                                </label>

                                                                <label>
                                                                    Qty:
                                                                    <input type="number" className="form-control" value={product.qty} disabled />
                                                                </label>

                                                                <label>
                                                                    Price:
                                                                    <input type="number" className="form-control" value={product.price} disabled />
                                                                </label>

                                                                <button type='button' id="add" className="btn btn-danger btn-sm ml-2" onClick={() => handleDeleteProduct(index)}>
                                                                    -
                                                                </button>
                                                                <br/>
                                                            </>

                                                        ))}
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </Form.Group>
                                </div>
                            </Tab>




                            <Tab eventKey="invoicing" title="Proposal">
                                <div className='biiling-inshights product'>
                                    

                                    <Form.Group controlId="transId">
                                        <Form.Label>Nature of  Supply</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Nature of  Supply"
                                            name="natureOfSupply"
                                            value={formData.natureOfSupply}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="transId">
                                        <Form.Label>Capex</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Nature of  Expense"
                                            name="natureOfExpense"
                                            value={formData.natureOfExpense}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="transId">
                                        <Form.Label>Opex</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Name of Requester"
                                            name="requester"
                                            value={formData.requester}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="transId">
                                        <Form.Label>Name of Order Creator</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Name of Order Creator"
                                            name="orderCreatedBy"
                                            value={formData.orderCreatedBy}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>


                                    <Form.Group controlId="transId">
                                        <Form.Label>Name of Order Approver</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Name of Order Approver"
                                            name="orderApprovedBy"
                                            value={formData.orderApprovedBy}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                </div>

                            </Tab>

                            <Tab eventKey="tax" title="Tax & Discount">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="discount">
                                        <Form.Label>Discount (in %)</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="number"
                                            placeholder="Enter Discount"
                                            name="discount"
                                            value={formData.discount}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    {/* Tax Rate */}
                                    <Form.Group controlId="taxRate">
                                        <Form.Label>Tax type</Form.Label>
                                        <Form.Control
                                            className='border mb-3' 
                                            as={'select'}
                                            placeholder="Enter Tax Type"
                                            name="taxtype"
                                            value={formData.taxType}
                                            onChange={(e)=>{setFormData({...formData,taxType:e.target.value})}}
                                        >
                                            <option value={''}>Select</option>
                                            <option value={'igst'}>IGST</option>
                                            <option value={'cgst'}>CGST</option>

                                        </Form.Control>
                                    </Form.Group>

                                    {/* Tax Rate */}
                                    {formData.taxType === 'igst' ? (
                                        <Form.Group controlId="taxRate">
                                            <Form.Label>IGST</Form.Label>
                                            <Form.Control
                                                className='border mb-3'
                                                type="number"
                                                placeholder="Enter Tax Rate"
                                                name="taxRate"
                                                value={formData.igst}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    ) :
                                        (
                                            <>
                                                <Form.Group controlId="taxRate">
                                                    <Form.Label>CGST</Form.Label>
                                                    <Form.Control
                                                        className='border mb-3'
                                                        type="number"
                                                        placeholder="Enter Tax Rate"
                                                        name="taxRate"
                                                        value={formData.cgst}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group controlId="taxRate">
                                                    <Form.Label>SGST</Form.Label>
                                                    <Form.Control
                                                        className='border mb-3'
                                                        type="number"
                                                        placeholder="Enter Tax Rate"
                                                        name="taxRate"
                                                        value={formData.sgst}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </>
                                        )}

                                </div>

                            </Tab>

                            <Tab eventKey="transactions" title="Transaction Details">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="transNo">
                                        <Form.Label>Transaction Number</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Transaction Number"
                                            name="transNo"
                                            value={formData.transNo}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                


                                    {/* Reference Quotation Number */}
                                    <Form.Group controlId="refQuotationNumber">
                                        <Form.Label>Quotation Number</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Quotation Number"
                                            name="quotationNo"
                                            value={formData.quotationNo}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    {/* Reference Quotation Number */}
                                    <Form.Group controlId="taxInvoiceNumber">
                                        <Form.Label>Order Number</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Order Number"
                                            name="orderNumber"
                                            value={formData.orderNumber}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    {/* GSTIN */}
                                    <Form.Group controlId="gstin">
                                        <Form.Label>GSTIN</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter GSTIN"
                                            name="vendorGSTIN"
                                            value={formData.vendorGSTIN}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </div>

                            </Tab>


                            <Tab eventKey="other" title="Other">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="refPONumber">
                                        <Form.Label>Due Date</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="date"
                                            placeholder="Enter Expiry"
                                            name="orderExpiry"
                                            value={formData.orderExpiry}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="shipOrHandCharge">
                                        <Form.Label>Shipping or Handling Charge</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="number"
                                            placeholder="Enter Shipping or Handling Charge"
                                            name="shipOrHandCharge"
                                            value={formData.shipOrHandCharge}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="site">
                                        <Form.Label>Note to Supplier</Form.Label>
                                        <textarea
                                           id="textArea"
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Terms"
                                            name="noteToSupplier"
                                            value={formData.noteToSupplier}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="site">
                                        <Form.Label>Terms</Form.Label>
                                        <textarea
                                        id="textArea"
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Terms"
                                            name="orderTerms"
                                            value={formData.orderTerms}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </div>

                            </Tab>
                        </Tabs>
                    </div>

                    <div className='customer-invoice-tabs'>
                        <Button variant="primary" id="draft" onClick={() => setFormData({ ...formData, poStatus: "draft" })} type="submit">
                            Draft
                        </Button>

                        <Button className='m-5' id="final" onClick={() => setFormData({ ...formData, poStatus: "complete" })} variant="primary" type="submit">
                            Save
                        </Button></div>


                </Form>



            </div>
        </div>
    );
};

export default EditPo;
