import { BsThreeDots } from 'react-icons/bs';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import InvoiceNumberComponent from '../Format';
const ProposalList = (props) => {
  props.setShow(true);
  const user=props.user;
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [editInvoiceDetail, setEditInvoiceDetail] = useState(false);
  const [filter, setFilter] = useState({ filter: '', asc: "no" });
 

  const handleDelete = (invoiceId) => {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}proposal/${invoiceId}`,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {
        if (response.data.status) {
          window.location.reload();
          Swal.fire({
            icon: "success",
            title: "Proposal Deleted Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }

      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  const handleSendToCa = (invoice) => {
    invoice.vendorIds.push(user?.caId);
    let data = JSON.stringify({
      sendToCa: true,
      vendorIds:invoice.vendorIds
    });
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}proposal/${invoice._id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      data: data
    }
    axios.request(config)
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Proposal Sent to CA Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        }

      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });

  }

  const handleAddInvoice = () => {
    navigate("/create-proposal")
  };

  const handleView=(invoiceId)=>{
    const url = `/proposal/${invoiceId}/view`;
    window.open(url, '_blank');
  }

  useEffect(() => {
    handleFilter();
  }, [])

  const handleFilter = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      };

      const response = await axios.get(`${process.env.REACT_APP_HOST}filterproposals?filter=${filter.filter}&asc=${filter.asc}`, config);
      setInvoices(response.data.proposals);
    } catch (error) {
      setInvoices([]);
    }
  };


  return (
    <div className="container-right">
    <div className="container mt-5 mt-0 d-flex flex-column">
    <div className='filter'  >
          <select onChange={(e) => setFilter({ ...filter, filter: e.target.value })}>
            <option value={'todays'}>Today's</option>
            <option value={'Last3months'}>Last 3 months</option>
            <option value={'Last6months'}>Last 6 months</option>
            <option value={'Last12months'}>Last 12 months</option>
            <option value={'Draft'}>Draft</option>

          </select>

          <select onChange={(e) => setFilter({ ...filter, asc: e.target.value })}>
            <option value={'yes'}>Ascending</option>
            <option value={'no'}>Descending</option>
          </select>
          <button type='button' onClick={handleFilter}>Search</button>
        </div>
      <div className="d-flex justify-content-end">
      
       
        <button className="btn btn-dark new" onClick={handleAddInvoice}>
          + New
        </button>
      </div>
     

      
      <table className="table table-striped">
        {/* Table headers */}
        <thead>
          <tr>
          <th scope="col">
          <td><input type="checkbox"/></td>
          </th>
            <th scope="col">Date</th>
            <th scope="col">Proposal#</th>
            <th scope="col">Organisation Name</th>
            <th scope="col">Status</th>
            <th scope="col">Amount</th>
            <th scope="col">Manage</th>
          </tr>
        </thead>
        <tbody>
          {invoices?.map((invoice, index) => (
            <tr className={index % 2 === 0 ? "bg-light" : "bg-secondary text-black"}>
              <td><input type="checkbox"/></td>
              <td>{moment(invoice.createdDate).format('DD-MM-YYYY')}</td>
              <td> <InvoiceNumberComponent page={'QT'} billNumber={invoice.billNumber} /></td>
              <td>{invoice.orgName}</td>
             
              <td>
                {invoice.proposalStatus}
              </td>
              <td>
                {invoice.totalINR}
              </td>
           
              <td>
                <DropdownButton id="dropdown-item-button btn-dark" variant='dark' title={<BsThreeDots size={26} />}>
                {/*<button disabled={invoice.proposalStatus !== 'complete'} onClick={() => handleSendToCa(invoice)} className='btn btn-secondary' >{invoice.sendToCa ? "Sent" : "Send"}</button>
                */}<button type='button' onClick={() => handleView(invoice._id)} className="btn btn-secondary">
                  <FaCloudDownloadAlt size={26} color="white" />
                </button>
                  <Dropdown.Item as="button" onClick={()=>handleView(invoice._id)}>View</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => navigate(`/proposal/edit/${invoice._id}`)} >Edit</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => navigate(`/create-invoice/${invoice._id}`)}>Convert To Invoice</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => handleDelete(invoice._id)}>Delete</Dropdown.Item>
                </DropdownButton>
              </td>
            </tr>
          ))}


        </tbody>
      </table>
    </div>
    
    </div>
  );
};

export default ProposalList;

