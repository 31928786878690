// InvoiceNumberComponent.js

import React from 'react';

const InvoiceNumberComponent = ({ page, billNumber }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100; // Get the last two digits of the current year
  const isFinancialYearAprilStart = currentDate.getMonth() >= 3;
  const currentFinancialYear = isFinancialYearAprilStart ? currentYear : currentYear - 1;

  const invoiceNumberFormat = `${page}${currentFinancialYear}0${billNumber}`;

  return (
    <>
      {invoiceNumberFormat}
    </>
  );
};

export default InvoiceNumberComponent;
