import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/flo.jpeg";
import Dropdown from 'react-bootstrap/Dropdown';
import '../../assets/css/sidebar.css'
import Home from "../../assets/images/svg/home.svg";
import Cust from "../../assets/images/svg/cust.svg";
import Invoice from "../../assets/images/svg/invoices.svg";
import Reports from "../../assets/images/svg/reports.svg";
import Settings from "../../assets/images/svg/settingd.svg";
import Noti from "../../assets/images/svg/notif.svg";
import { RiUserSmileLine } from "react-icons/ri";
import INv from "../../assets/images/svg/Avatar.svg";

const Sidebar = (props) => {
  
  const navigate = useNavigate();
  const user=props.user;
  const [activeLink, setActiveLink] = useState(localStorage.getItem('activeLink'));
  const [dashboardData, setDashboardData] = useState({});
  const userToken = localStorage.getItem("userToken");
  const handleLinkClick = (link) => {
    setActiveLink(link);
    localStorage.setItem("activeLink",link)
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}user`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${userToken}`, // Replace with your actual access token
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setDashboardData(data.data);
           
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    fetchDashboardData();
}, []); 
 
  

  const handleLogout = () => {
    window.localStorage.clear();
    localStorage.removeItem("userToken");
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    if (localStorage.getItem("userToken") === null || "") {
      navigate("/");
    }
  }, []);

  const settings = () => {
    navigate("/settings");
  };
  const getLinkName = () => {

    switch (activeLink) {
      case 'dashboard':
        return 'Home';
      case 'peoples':
        return 'List of Customers';
      case 'invoices':
        return 'List of Invoices';
      case 'purchaseorders':
        return 'Purchase Order';
      case 'proposals':
        return 'Proposals';
      case 'challans':
        return 'Delivery Challan';
      default:
        return '';
    }
  };

  return (
    <>

    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={Logo} alt="Logo" /> 
      </div>
      <div className="menu">
      <div className={`menu-items ${activeLink === 'dashboard' ? 'active' : ''}`}>
        <Link to="/dashboard" onClick={() => handleLinkClick('dashboard')}>
          <img src={Home} alt="Home" />
          <p>Home</p>
        </Link>
      </div>

      <div className={`menu-items ${activeLink === 'peoples' ? 'active' : ''}`}>
        <Link to="/peoples" onClick={() => handleLinkClick('peoples')}>
          <img src={Cust} alt="Customer" />
          <p>Customer</p>
        </Link>
      </div>

      <div className={`menu-items ${activeLink === 'invoices' ? 'active' : ''}`}>
        <Link to="/invoices" onClick={() => handleLinkClick('invoices')}>
          <img src={Invoice} alt="Invoices" />
          <p>Invoices</p>
         
        </Link>
        {activeLink === 'invoices' ? <Link to="/create-invoice/new"><span id="activeIcon">+</span></Link> : null}

       
      </div>

      <div className={`menu-items ${activeLink === 'purchaseorders' ? 'active' : ''}`}>
        <Link to="/purchaseorders" onClick={() => handleLinkClick('purchaseorders')}>
          <img src={Invoice} alt="Purchase Order" />
          <p>Purchase Order</p>
        </Link>
        {activeLink === 'purchaseorders' ? <Link to="/create-purchaseorder"><span id="activeIcon">+</span></Link> : null}
      </div>

      <div className={`menu-items ${activeLink === 'proposals' ? 'active' : ''}`}>
        <Link to="/proposals" onClick={() => handleLinkClick('proposals')}>
          <img src={Invoice} alt="Proposals" />
          <p>Proposals</p>
        </Link>
        {activeLink === 'proposals' ? <Link to="/create-proposal"><span id="activeIcon">+</span></Link> : null}
      </div>

      <div className={`menu-items ${activeLink === 'challans' ? 'active' : ''}`}>
        <Link to="/challans" onClick={() => handleLinkClick('challans')}>
          <img src={Invoice} alt="Challan" />
          <p>Challans</p>
        </Link>
        {activeLink === 'challans' ? <Link to="/create-challan"><span id="activeIcon">+</span></Link> : null}
      </div>
    </div>

      <div className="menu">
    

        <div className="menu-items">
         <Link to="/settings">
           <img src={Settings} alt="Home" />
           <p>Settings</p>
          </Link>
        </div>


        
      </div>

      <div className="sidebar-bottom">
        <div className="sidebar-profile">
            <img src={INv} />
            <p>{dashboardData.fullName}</p>
            <button onClick={handleLogout}>
              Logout
            </button>
        </div>
      </div>
    </div>
    <div className="sidebar-top">
       <div className="sidebar-top-left">
       <p>{getLinkName()} </p>

     
      
       </div>
       <div className="sidebar-top-right">
         <Link to="">
           <img src={Noti} />
         </Link>
         <div className="business-name">
           <p>{dashboardData.orgName}</p>
           <RiUserSmileLine  id="user" />
         </div>
        </div>
    </div>

   

  



    </>
  );
};

export default Sidebar;
