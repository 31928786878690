import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const InvoiceForm = (prop) => {
    prop.setShow(true);
    const [key, setKey] = useState('personal');
    const propId = useParams().propId;
    const userToken = localStorage.getItem("userToken");
    const navigate = useNavigate();
    const [vendors, setVendors] = useState([]);
    const [newProduct, setNewProduct] = useState({ name: '', uom: '', qty: 0, price: 0, hsn: "" });
    const [selectedVendor, setselectedVendor] = useState({});
    const [logos, setlogos] = useState([]);
    const [terms, setTerms] = useState([""]);
    const [formData, setFormData] = useState({
        logoURL: '',
        proposalStatus: '',
        quoteNumber: '',
        vaildity: '',
        terms: [],
        isAccepted: '',
        productDesc: [],
        discount: '',
        taxRate: '',
        shipOrHandCharge: '',
        productType: '',
        transNo: '',
        transId: '',
        igst: 18,
        cgst: 9,
        sgst: 9,
        taxType: ''
    });


    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}proposal/${propId}`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {

                const proposal = response.data.proposal;
                setFormData({
                    ...formData,
                    vendorId: proposal?.vendorId,
                    orgName: proposal?.orgName,
                    transNo: proposal?.transNo,
                    transId: proposal?.transId,
                    customAddress: proposal?.customAddress,
                    customName: proposal?.customName,
                    gstin: proposal?.gstin,
                    productDesc: proposal?.productDesc,
                    discount: proposal?.discount,
                    taxRate: proposal?.taxRate,
                    shipOrHandCharge: proposal?.shipOrHandCharge,

                })
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])





    const loadVendors = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}customersmine`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {
                setVendors(response.data.customers);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const loadLogos = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}alllogos`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {

                setlogos(response.data.logos);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        loadVendors();
        loadLogos();
    }, []);

    const handleAddProduct = () => {
        const { name, uom, qty, price, hsn } = newProduct;
        const updatedProducts = [...formData.productDesc, { name, uom, qty, price, hsn }];
        setFormData({
            ...formData,
            productDesc: updatedProducts,
        });
        setNewProduct({ name: '', uom: '', qty: 0, price: 0, hsn: '' });
    };

    const handleDeleteProduct = (index) => {
        const updatedProducts = formData.productDesc.filter((product, i) => i !== index);
        setFormData({ productDesc: updatedProducts });
    };

    const handleAddTerm = () => {
        setTerms([...terms, '']);
    };

    const handleTermChange = (index, value) => {
        const newTerms = [...terms];
        newTerms[index] = value;
        setTerms(newTerms);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setNewProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
    };


    const handleSubmit = async (e) => {



        e.preventDefault();
        let data = JSON.stringify({
            ...formData,
            terms: terms,
            

        });


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}proposal`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            data: data
        }
        axios.request(config)
            .then((response) => {
                if (response?.data.status) {
                    navigate("/proposals");
                    Swal.fire({
                        icon: "success",
                        title: "Proposal Created Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }

            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "warning",
                    title: error.response?.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });

    };

    const handleVendorSelected = (s) => {
        setFormData({
            ...formData,
            customPhone: s?.phone,
            customName: s?.customName || s?.orgName,
            vendorIds: s?._id,
            orgName: s?.orgName,
            customAddress: s?.address,
            gstin: s?.gstNo,
            customId: s?.customId

        })
    }


    return (
        <div className="container-right">
            <div className='container mt-5 mb-5'>
                <h2 className='font-weight-bolder mt-4 mb-4'>  New Proposal</h2>
                <Form onSubmit={handleSubmit}>
                    <div className='create-invoice'>
                        <div className='label-input'>
                            <label>Organisation Name*</label>
                            <Form.Control
                                className='border mb-3'
                                as="select"
                                placeholder="Vendor Name"
                                name="vendorIds"
                                value={selectedVendor.customName}  // Use an empty string as the default value
                                onChange={(e) => {
                                    const selectedVendorId = e.target.value ? JSON.parse(e.target.value)._id : '';
                                    setselectedVendor(e.target.value ? JSON.parse(e.target.value) : null);
                                    handleVendorSelected(JSON.parse(e.target.value));

                                }}
                            >
                                <option value={'{}'}></option>

                                {vendors.map((v) => (
                                    <option key={v._id} value={JSON.stringify(v)}>{v.orgName}</option>
                                ))}

                            </Form.Control>

                            <Form.Group controlId="taxInvoiceNumber">
                                <Form.Label>Organisation Name*</Form.Label>
                                <Form.Control
                                    className='border mb-3'
                                    type="text"
                                    placeholder="Enter Organisation Name"
                                    name="orgName"
                                    value={selectedVendor?.orgName}
                                    onChange={(e) => setselectedVendor({ ...selectedVendor, orgName: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="taxInvoiceNumber">

                                <Form.Label>Contact Name*</Form.Label>
                                <Form.Control
                                    className='border mb-3'
                                    type="text"
                                    placeholder="Enter Organisation Name"
                                    name="customName"
                                    value={selectedVendor?.customName}
                                    onChange={(e) => setselectedVendor({ ...selectedVendor, customName: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group controlId="taxInvoiceNumber">
                                <Form.Label>Organisation Phone*</Form.Label>
                                <Form.Control
                                    className='border mb-3'
                                    type="text"
                                    placeholder="Enter Organisation Phone"
                                    name="customPhone"
                                    value={selectedVendor?.phone}
                                    onChange={(e) => setselectedVendor({ ...selectedVendor, phone: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group controlId="taxInvoiceNumber">
                                <Form.Label>Organisation Address*</Form.Label>
                                <Form.Control
                                    className='border mb-3'
                                    type="text"
                                    placeholder="Enter Organisation Address"
                                    name="customAddress"
                                    value={selectedVendor?.address}
                                    onChange={(e) => setselectedVendor({ ...selectedVendor, address: e.target.value })}
                                />
                            </Form.Group>



                        </div>
                    </div>

                    <div className='customer-invoice-tabs'>

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="personal" title="Item Table">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="productDesc">

                                        <div className='mb-4'>
                                            <div className="row">
                                                <div className="col-sm product">
                                                    <label>
                                                        Product Name:
                                                        <textarea rows={1} type="text" className="form-control" name="name" value={newProduct.name} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        UOM:
                                                        <select className="form-control" name="uom" value={newProduct.uom} onChange={handleInputChange2}>
                                                            <option></option>
                                                            <option value={'Nos'}>Nos</option>
                                                            <option value={'Litre'}>Litre</option>
                                                            <option value={'Kgs'}>Kgs</option>
                                                            <option value={'Lots'}>Lots</option>

                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        HSN Code:
                                                        <input type="text" className="form-control" name="hsn" value={newProduct.hsn} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        qty:
                                                        <input type="number" className="form-control" name="qty" value={newProduct.qty} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        Price:
                                                        <input type="number" className="form-control" name="price" value={newProduct.price} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm-auto align-self-end">
                                                    <button type='button' className="btn btn-primary" id="add" onClick={handleAddProduct}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='added-items'>
                                            <h6>Added Items</h6>
                                            <div className='mb-4'>
                                                <div className="row">
                                                    <div className="col-sm added">
                                                        {formData?.productDesc?.map((product, index) => (
                                                            <>
                                                                <label>
                                                                    Product Name:
                                                                    <textarea rows={1} type="text" className="form-control" value={product.name} disabled />
                                                                </label>
                                                                <label>
                                                                    UOM:
                                                                    <input type="text" className="form-control" value={product.uom} disabled />
                                                                </label>
                                                                <label>
                                                                    HSN Code:
                                                                    <input type="text" className="form-control" value={product.hsn} disabled />
                                                                </label>

                                                                <label>
                                                                    Qty:
                                                                    <input type="number" className="form-control" value={product.qty} disabled />
                                                                </label>

                                                                <label>
                                                                    Price:
                                                                    <input type="number" className="form-control" value={product.price} disabled />
                                                                </label>

                                                                <button type='button' id="add" className="btn btn-danger btn-sm ml-2" onClick={() => handleDeleteProduct(index)}>
                                                                    -
                                                                </button>
                                                                <br />
                                                            </>

                                                        ))}
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </Form.Group>
                                </div>
                            </Tab>




                            <Tab eventKey="invoicing" title="Logo">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="invType">
                                        <Form.Label>Logo Type</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as="select"

                                            name="logoURL"
                                            value={formData.logoURL}
                                            onChange={handleInputChange}
                                        >
                                            {logos?.map((l) => (
                                                <option value={l.url}>{l.logoName}</option>
                                            ))}

                                        </Form.Control>
                                    </Form.Group>

                                </div>

                            </Tab>

                            <Tab eventKey="tax" title="Tax & Discount">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="discount">
                                        <Form.Label>Discount (in %)</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="number"
                                            placeholder="Enter Discount"
                                            name="discount"
                                            value={formData.discount}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    {/* Tax Rate */}
                                    <Form.Group controlId="taxRate">
                                        <Form.Label>Tax type</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as={'select'}
                                            placeholder="Enter Tax Type"
                                            name="taxtype"
                                            value={formData.taxType}
                                            onChange={(e) => { setFormData({ ...formData, taxType: e.target.value }) }}
                                        >
                                            <option value={''}>Select</option>
                                            <option value={'igst'}>IGST</option>
                                            <option value={'cgst'}>CGST</option>

                                        </Form.Control>
                                    </Form.Group>

                                    {/* Tax Rate */}
                                    {formData.taxType === 'igst' ? (
                                        <Form.Group controlId="taxRate">
                                            <Form.Label>IGST</Form.Label>
                                            <Form.Control
                                                className='border mb-3'
                                                type="number"
                                                placeholder="Enter Tax Rate"
                                                name="taxRate"
                                                value={formData.igst}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    ) :
                                        (
                                            <>
                                                <Form.Group controlId="taxRate">
                                                    <Form.Label>CGST</Form.Label>
                                                    <Form.Control
                                                        className='border mb-3'
                                                        type="number"
                                                        placeholder="Enter Tax Rate"
                                                        name="taxRate"
                                                        value={formData.cgst}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group controlId="taxRate">
                                                    <Form.Label>SGST</Form.Label>
                                                    <Form.Control
                                                        className='border mb-3'
                                                        type="number"
                                                        placeholder="Enter Tax Rate"
                                                        name="taxRate"
                                                        value={formData.sgst}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </>
                                        )}

                                </div>

                            </Tab>

                            {/*<Tab eventKey="transactions" title="Transaction Details">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="transNo">
                                        <Form.Label>Transaction Number</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Transaction Number"
                                            name="transNo"
                                            value={formData.transNo}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="transId">
                                        <Form.Label>Transaction Id</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Transaction Id"
                                            name="transId"
                                            value={formData.transId}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>



                                   
                                    <Form.Group controlId="refQuotationNumber">
                                        <Form.Label>Quotation Number</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Quotation Number"
                                            name="quoteNumber"
                                            value={formData.quoteNumber}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                           
                                    <Form.Group controlId="taxInvoiceNumber">
                                        <Form.Label>Customer Id</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Customer Id"
                                            name="customId"
                                            value={formData.customId}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="gstin">
                                        <Form.Label>GSTIN</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter GSTIN"
                                            name="gstin"
                                            value={formData.gstin}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </div>

                            </Tab>*/}


                            <Tab eventKey="other" title="Other">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="refPONumber">
                                        <Form.Label>Validity</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="date"
                                            placeholder="Enter Validity"
                                            name="vaildity"
                                            value={formData.vaildity}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="shipOrHandCharge">
                                        <Form.Label>Shipping or Handling Charge</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="number"
                                            placeholder="Enter Shipping or Handling Charge"
                                            name="shipOrHandCharge"
                                            value={formData.shipOrHandCharge}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="shipOrHandCharge">
                                        <Form.Label>Customer Id</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Customer's Id"
                                            name="customId"
                                            value={formData.customId}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="site">
                                        <Form.Label>Terms and Conditions</Form.Label>
                                        {terms?.map((term, index) => (
                                            <div key={index}>
                                                <Form.Control
                                                    className='border mb-3'
                                                    as="textarea"
                                                    placeholder={`Enter Term ${index + 1}`}
                                                    value={term}
                                                    onChange={(e) => handleTermChange(index, e.target.value)}
                                                />
                                            </div>
                                        ))}
                                        <Button type='button' style={{ marginLeft: "0px" }} variant="primary" onClick={handleAddTerm}>+ Add Term</Button>
                                    </Form.Group>
                                </div>

                            </Tab>
                        </Tabs>
                    </div>

                    <div className='customer-invoice-tabs'>
                        <Button variant="primary" id="draft" onClick={() => setFormData({ ...formData, proposalStatus: "draft" })} type="submit">
                            Draft
                        </Button>

                        <Button className='m-5' id="final" onClick={() => setFormData({ ...formData, proposalStatus: "complete" })} variant="primary" type="submit">
                            Save
                        </Button></div>


                </Form>



            </div>
        </div>
    );
};

export default InvoiceForm;
